/**
* Generated automatically at built-time (2024-08-26T12:09:16.439Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "3d-guide-utekjokken",templateKey: "sites/100-994b8dfe-6515-4828-bcaf-1d649ec5620d"};