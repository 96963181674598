/**
* Generated automatically at built-time (2024-08-26T12:09:16.662Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekjokken-kjopsguide",templateKey: "sites/100-66a19360-b74d-49c0-8ef1-ced13b9ee11c"};