/**
* Generated automatically at built-time (2024-08-26T12:09:16.674Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "utekjokken-beskyttelsestrekk-custom-made",templateKey: "sites/100-85eed7c0-556c-4624-98e3-437d65678d5b"};